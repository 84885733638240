import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Col, Row } from "react-bootstrap";
import useSWR from "swr";
import { DateTime } from "luxon";

async function fetcher<JSON = any>(
  input: RequestInfo,
  init?: RequestInit
): Promise<JSON> {
  const res = await fetch(input, init);
  return res.json();
}

function App() {
  const { data, error } = useSWR<any[], any>(
    "https://data.sportlink.com/programma?weekoffset=0&sorteervolgorde=datum&aantaldagen=365&aantalregels=1&teamcode=166070&client_id=pyPdm0NEJP",
    fetcher
  );

  return (
    <div style={{ width: 320, height: 80, overflow: "hidden" }}>
      <Row
        className="h-100 justify-content-between"
        style={{ backgroundColor: "#003882" }}
      >
        <Col xs="auto" className="align-self-center">
          <p className="h1 text-white px-2 mb-0 pt-2" style={{ fontSize: 18 }}>
            {`${DateTime.fromISO(data?.[0]?.wedstrijddatum).toFormat(
              "EEEE DD HH:mm",
              { locale: "nl" }
            )}`}
          </p>
          <p className="fw-bold text-white px-2" style={{ fontSize: 28 }}>
            {`${data?.[0]?.thuisteam} - ${data?.[0]?.uitteam}`}
          </p>
        </Col>
      </Row>
    </div>
  );
}

export default App;
